/* eslint-disable @typescript-eslint/no-unused-vars */
import { Api } from './axios-config';
import { setHeaderAuthorization } from '../../utils/apiHelper';

export interface ICompanyInfo {
  id: number;
  name: string;
  cnpj: string;
}

type TContactCount = {
  currentPage: number;
  data: ICompanyInfo[];
  perPage: number;
  total: number;
}

const getAll = async (currentPage: number = 1, perPage: number = 5, name: string): Promise<TContactCount | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/CompanyInfo?currentPage=${currentPage}&perPage=${perPage}${name !== '' ? `&name=${name}` : ''}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: number): Promise<ICompanyInfo | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/CompanyInfo/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: ICompanyInfo): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    const { id, ...dataSend } = dados;
    await Api.post<ICompanyInfo>('/CompanyInfo', dataSend);

  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const update = async (id: number, dados: ICompanyInfo): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.put(`/CompanyInfo/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const exclude = async (id: number): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.delete(`/CompanyInfo/${id}`);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const getAllNoPage = async (paginating: boolean): Promise<ICompanyInfo[] | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/CompanyInfo?currentPage=0&perPage=0&paginating=${paginating}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};



export const CompanyInfoService = {
  getAll,
  getById,
  create,
  update,
  exclude,
  getAllNoPage
};
