import { Api } from './axios-config';
import { setHeaderAuthorization } from '../../utils/apiHelper';
import { IUser } from './UsersService';

export interface ISigningRoles {
  id: number;
  description: string;
  email?: string;
  userId?: number;
  associatedUsers?: IUser[];
}

export interface ISigningRolesUpdate {
  id: number;
  description: string;
  email?: string;
  associatedUsers?: number[];
}

type TSigningRolesCount = {
  currentPage: number;
  data: ISigningRoles[];
  perPage: number;
  total: number;
}

const getAll = async (currentPage: number, perPage: number, paginating: boolean = true): Promise<TSigningRolesCount | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/SigningRoles?currentPage=${currentPage}&perPage=${perPage}&paginating=${paginating}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: number): Promise<ISigningRoles | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/SigningRoles/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: ISigningRoles): Promise<number | Error> => {
  try {
    setHeaderAuthorization();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...dataSend } = dados;
    const { data } = await Api.post<ISigningRoles>('/SigningRoles', dataSend);

    if (data) {
      return data.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const update = async (id: number, dados: ISigningRolesUpdate): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.put(`/SigningRoles/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const exclude = async (id: number): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.delete(`/SigningRoles/${id}`);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

const getAllNoPage = async (paginating: boolean): Promise<ISigningRoles[] | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.get(`/SigningRoles?currentPage=0&perPage=0&paginating=${paginating}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};


export const SigningRolesService = {
  getAll,
  getById,
  create,
  update,
  exclude,
  getAllNoPage
};
