export const formatHelper = {
  formatCNPJ(cnpj: string): string {
    // Remove qualquer caractere que não seja número
    cnpj = cnpj.replace(/\D/g, '');

    // Aplica a formatação: 99.999.999/9999-99
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  },

  formatCPF(cpf: string): string {
    // Remove qualquer caractere que não seja número
    cpf = cpf.replace(/\D/g, '');

    // Aplica a formatação: 999.999.999-99
    return cpf.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      '$1.$2.$3-$4'
    );
  }
};
