import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Col, ConfigProvider, Empty, Input, Modal, notification, Popconfirm, Row, Space, Table, TablePaginationConfig } from 'antd';
import { CheckCircleOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

import { IUser, UsersService } from '../services/api/UsersService';
import { useDebounce } from '../hooks';

interface UserTableProps {
  dataTable: IUser[];
  isLoading: boolean;
  handleRemoveUser: (id: number) => void;
  handleAddUser: (user: IUser) => void;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

export const AssociatedUsersTable: React.FC<UserTableProps> = ({ dataTable, isLoading, handleRemoveUser, handleAddUser }) => {
  const { debounce } = useDebounce();

  const [isModalUser, setIsModalUser] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]); // Usuários do modal
  const [search, setSearch] = useState<string>('');
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  const handleTableChangeUser = (
    pagination: TablePaginationConfig,
  ) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setUsers([]);
    }
  };

  useEffect(() => {
    debounce(() => {
      UsersService.getAll(tableParams.pagination?.current, tableParams.pagination?.pageSize, search, false)
        .then((result) => {
          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            setUsers(result.data);

            setTableParams({
              ...tableParams,
              pagination: {
                ...tableParams.pagination,
                total: result.total,
              },
            });
          }
        });
    });
  }, [JSON.stringify(tableParams), search]);

  const handleUserSelection = (user: IUser) => {
    handleAddUser(user);
    setIsModalUser(false);
    setSearch('');
  };

  const columns: ColumnsType<IUser> = [
    {
      title: 'Usuário',
      dataIndex: 'name',
      key: 'name',
    },
    {
      key: 'actions',
      width: '10%',
      render: (record: IUser) => (
        <Popconfirm
          title="deseja desassociar este usuário?"
          onConfirm={() => handleRemoveUser(record.id)}
          okText="Sim"
          cancelText="Não"
          placement="left"
        >
          <Button type="text" shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    }
  ];

  const columnsUser: ColumnsType<IUser> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Selecionar',
      key: 'actions',
      width: '10%',
      render: (record: IUser) => (
        <Space size="small" style={{ justifyContent: 'center', display: 'flex' }}>
          <Button type="text" shape="circle" icon={<CheckCircleOutlined />} onClick={() => handleUserSelection(record)} />
        </Space>
      ),
    }
  ];

  const customizeRenderEmpty = () => (
    <Empty
      imageStyle={{ height: 60 }}
      description={
        <span>
          Nenhum usuário associado.
        </span>
      }
    />
  );

  return (
    <>
      <Row>
        <Col span={12} style={{ marginBottom: 10 }}>
          <Button type="dashed" onClick={() => setIsModalUser(true)} icon={<PlusOutlined />}>
            Adicionar usuário
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Table
              columns={columns}
              dataSource={dataTable}
              rowKey={(record) => record.id}
              loading={isLoading}
              size="small"
            />
          </ConfigProvider>
        </Col>
      </Row>

      <Modal
        open={isModalUser}
        width={800}
        onCancel={() => setIsModalUser(false)}
        title='Selecionar usuário'
        footer={[
        ]}
      >
        <Row style={{ marginBottom: 16 }}>
          <Col span={12}>
            <Input
              placeholder='Pesquisar usuário por nome'
              onChange={(e) => setSearch(e.target.value)}
              allowClear
              suffix={
                <SearchOutlined />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columnsUser}
              rowKey={(record) => record.id}
              dataSource={users}
              loading={isLoading}
              size="small"
              pagination={tableParams.pagination}
              onChange={handleTableChangeUser}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
