import { Navigate, Route, Routes } from 'react-router-dom';
import {
  Contacts,
  Documents,
  DetailDocument,
  NewDocument,
  Groups,
  GroupsFlow,
  Report,
  MySignatures,
  SigningRoles,
  Users,
  Roles,
  ValidateSignedDocumentAuth,
  DetailRoles,
  Templates,
  DetailTemplate,
  CreateDocument,
  Dashboard,
  PositionFields,
  MyProfile,
  CompanyInfo,
  SigningRoleEdit,
} from '../pages';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/documentos' element={<Documents />} />
      <Route path='/documentos/novo-documento' element={<NewDocument />} />
      <Route path='/documentos/detalhe/:key' element={<DetailDocument />} />
      <Route path='/documentos/meus-documentos' element={<MySignatures />} />
      <Route path='/documentos/validar-assinatura-documentos' element={<ValidateSignedDocumentAuth />} />

      <Route path='/pagina-inicial' element={<Dashboard />} />
      <Route path='/contatos' element={<Contacts />} />
      <Route path='/relatorio' element={<Report />} />
      <Route path='/usuarios' element={<Users />} />
      <Route path='/meu-perfil' element={<MyProfile />} />
      <Route path='/perfis-usuarios' element={<Roles />} />
      <Route path='/perfis-usuarios/:id' element={<DetailRoles />} />

      <Route path='/modelos' element={<Templates />} />
      <Route path='/modelos/:id' element={<DetailTemplate />} />
      <Route path='/modelos/posicionar-campos/:id' element={<PositionFields />} />
      <Route path='/modelos/gerar-documento/:id' element={<CreateDocument />} />

      <Route path='/fluxos' element={<Groups />} />
      <Route path='/fluxos/:id' element={<GroupsFlow />} />

      <Route path='/tipos-assinatura' element={<SigningRoles />} />
      <Route path='/tipos-assinatura/:id' element={<SigningRoleEdit />} />

      <Route path='/empresas' element={<CompanyInfo />} />

      <Route path="*" element={<Navigate to='/pagina-inicial' />} />
    </Routes>
  );
};
