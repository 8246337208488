import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  notification
} from 'antd';
import {
  PlusOutlined,
  FormOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { usePermission } from '../../shared/contexts/PermissionContext';
import { CompanyInfoService, ICompanyInfo } from '../../shared/services/api';
import { formatHelper } from '../../shared/utils/formatHelper';
import { validateCNPJ } from '../../shared/hooks/CnpjUtils';
import { InputMaskCNPJ } from '../../shared/components';

const { Title } = Typography;

export const CompanyInfo = () => {
  const { hasPermission } = usePermission();

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<ICompanyInfo[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limiteLinhas, setLimiteLinhas] = useState(10);

  const getData = useCallback(() => {
    setIsLoading(true);

    const limiteLinhas: number = parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS || '', 10);
    setLimiteLinhas(limiteLinhas);

    CompanyInfoService.getAll(currentPage, limiteLinhas, '')
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setDataTable(result.data);
          setTotalCount(result.total);
        }
      });
  }, [currentPage]);

  const openModal = () => {
    form.resetFields();
    setIsModalOpen(true);
    setModalTitle('Nova empresa');
  };

  const handleSave = async (data: ICompanyInfo) => {
    if (data.id) {
      setIsLoading(true);

      const cleanCNPJ = data.cnpj.replace(/\D/g, '');
      const cleanData = {
        ...data,
        cnpj: cleanCNPJ, // Envia o CNPJ sem máscara
      };

      CompanyInfoService
        .update(data.id, cleanData)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            notification.success({
              message: 'Empresa atualizado com sucesso!'
            });
            setIsModalOpen(false);
            getData();
          }
        });
    } else {
      setIsLoading(true);

      const cleanCNPJ = data.cnpj.replace(/\D/g, '');
      const cleanData = {
        ...data,
        cnpj: cleanCNPJ, // Envia o CNPJ sem máscara
      };

      CompanyInfoService
        .create(cleanData)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            notification.success({
              message: 'Empresa criado com sucesso!'
            });
            setIsModalOpen(false);
            getData();
          }
        });
    }
  };

  const handleRemove = async (id: number) => {
    setIsLoading(true);
    CompanyInfoService
      .exclude(id)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          notification.info({
            message: 'Empresa excluido com sucesso!'
          });
          setIsModalOpen(false);
          getData();
        }
      });
  };

  const hidrateFormWithValues = async (data: ICompanyInfo) => {
    form.setFieldsValue(data);
    setIsModalOpen(true);
    setModalTitle('Editar empresa');
  };

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: ColumnsType<ICompanyInfo> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      responsive: ['sm'],
      render: (cnpj: string) => formatHelper.formatCNPJ(cnpj),
    },
    {
      key: 'actions',
      render: (record: ICompanyInfo) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="text" shape="circle" icon={<FormOutlined />} onClick={() => hidrateFormWithValues(record)} disabled={!hasPermission(802)} />
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir esta empresa?"
            onConfirm={() => handleRemove(record.id)}
            okText="Sim"
            cancelText="Não"
            placement="left"
          >
            <Button type="text" shape="circle" icon={<DeleteOutlined />} disabled={!hasPermission(803)} />
          </Popconfirm>
        </Space>
      ),
    }
  ];

  const customizeRenderEmpty = () => (
    <Empty
      imageStyle={{ height: 60 }}
      description={
        <span>
          Nenhum registro encontrado.
        </span>
      }
    >
      <Button type="primary" icon={<PlusOutlined />} onClick={openModal} loading={isLoading} disabled={!hasPermission(800)}>
        Nova empresa
      </Button>
    </Empty>
  );

  return (
    <>
      <Row>
        <Col span={14}>
          <Title level={3}>Empresas</Title >
        </Col>
        <Col span={10} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="primary" onClick={openModal} icon={<PlusOutlined />} loading={isLoading} disabled={!hasPermission(800)}>
            Nova empresa
          </Button>
        </Col>
      </Row >
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          columns={columns}
          dataSource={dataTable}
          rowKey={(record) => record.id}
          loading={isLoading}
          size="small"
          pagination={false}
        />
        <Pagination
          pageSize={limiteLinhas}
          style={{ float: 'right', margin: '5px' }}
          current={currentPage}
          onChange={onChangePage}
          total={totalCount}
          showTotal={total => `Total ${total} registro(s)`}
        />
      </ConfigProvider>
      <Modal
        open={isModalOpen}
        closeIcon={false}
        footer={[
        ]}
      >
        <Title level={3}>{modalTitle}</Title>
        <Form
          layout="vertical"
          form={form}
          hideRequiredMark
          onFinish={handleSave}
          initialValues={{
            id: ''
          }}
        >
          <Row>
            <Form.Item name="id" style={{ display: 'none' }}>
              <Input readOnly name="id" />
            </Form.Item>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Nome"
                rules={[{ required: true, message: 'Nome é um campo obrigatório' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="cnpj"
                label="CNPJ"
                rules={[
                  { required: true, message: 'CNPJ é um campo obrigatório' },
                  { validator: validateCNPJ }
                ]}
              >
                <InputMaskCNPJ />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button onClick={() => setIsModalOpen(false)} style={{ marginRight: 8 }} htmlType="button">
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Salvar alterações
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
