/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api } from './axios-config';
import { setHeaderAuthorization } from '../../utils/apiHelper';
import { IFieldsTemplate } from './TemplatesService';

export interface IDocument {
  key: string;
  description: string;
  fileName: string;
  participants: IParticipant[];
  status: number,
  digitallySignedAt: Date;
  deadline?: string
  documentFileUrl: string;
  signedDocumentFileUrl: string;
  cancellationReason: string;
  cancelledAt: Date;
  createdAt: Date;
}

export interface IUpload {
  fileName: string;
  size: number
  fileType: number; // 0 - Document / 1 - Template
}

export interface IUploadResp {
  key: string;
  url: string;
}

export interface IParticipant {
  name: string;
  email: string;
  isSigner: boolean;
  signingPriority: number;
  signingRole?: number;
  canRefuse?: boolean;
  hasSigned?: boolean;
  signingUrl?: string;
  signature?: ISignature;
  fields?: IFieldsTemplate[];
}

export interface ISendDocument {
  key: string;
  description: string;
  deadline?: string,
  participants: IParticipant[];
  templateId?: number;
  signingFlowId?: number;
  isAutoSigning?: boolean;
}

export interface IDocumentResp {
  currentPage: number;
  data: IDocument[];
  perPage: number;
  total: number;
}

export interface ITotalByStatus {
  documentStatus: number;
  total: number;
}

export interface IDocumentValid {
  isValid: boolean;
}

export interface ICancelDocument {
  key: string;
  reason: string;
}

interface ISignature {
  name: string;
  cpf: string;
  ip: string;
  userAgent: string;
  signingRole: string;
  signedAt: Date;
}

// type TDocumentCount = {
//   documents: IDocumentResp;
//   totalByStatus: ITotalByStatus[];
// }

type TDocumentCount = {
  currentPage: number;
  data: IDocument[];
  perPage: number;
  total: number;
}

const getAll = async (currentPage: number, perPage: number, description?: string, documentStatus?: string, createdAtInterval?: string, participating?: boolean): Promise<TDocumentCount | Error> => {
  setHeaderAuthorization();

  try {
    let aux = `?currentPage=${currentPage}&perPage=${perPage}`;

    if (description) {
      aux = `${aux}&description=${description}`;
    }

    if (documentStatus) {
      aux = `${aux}&documentStatus=${documentStatus}`;
    }

    if (createdAtInterval) {
      aux = `${aux}&createdAtInterval=${createdAtInterval}`;
    }

    if (participating) {
      aux = `${aux}&participating=${participating}`;
    }

    const { data } = await Api.get(`/Documents${aux}`);

    if (data) {
      return data;
    }

    return new Error('Desculpe, não foi possível encontrar os Documentos.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Desculpe, não foi possível encontrar os Documentos.');
  }
};

const getById = async (key: string): Promise<IDocument | Error> => {
  setHeaderAuthorization();

  try {
    const { data } = await Api.get(`/documents/${key}`);
    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o Documento.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar o Documento.');
  }
};

const create = async (dados: ISendDocument): Promise<void | Error> => {
  setHeaderAuthorization();

  // dados.participants[0].fields = JSON.parse('[{"Description":"Informações complementares","Key":"b13a18cd-7039-a49b-264a-8eff39f2dced","Type":1,"PositionX":283.0,"PositionY":589.0,"IsRequired":true,"PageNumber":1,"BoundsX":240.0,"BoundsY":1099.0},{"Description":"Nome completo","Key":"78060085-3090-0a37-7339-297cecb7256d","Type":2,"PositionX":283.0,"PositionY":609.0,"IsRequired":true,"PageNumber":1,"BoundsX":240.0,"BoundsY":1099.0},{"Description":"E-mail Signatario","Key":"57cfcef5-347c-c82d-d118-d6daab76ca5f","Type":3,"PositionX":283.0,"PositionY":623.0,"IsRequired":true,"PageNumber":1,"BoundsX":240.0,"BoundsY":1099.0},{"Description":"Data Nascimento","Key":"ddc4c7d0-e3ba-04f8-5807-6387c503e65a","Type":4,"PositionX":281.0,"PositionY":643.0,"IsRequired":true,"PageNumber":1,"BoundsX":240.0,"BoundsY":1099.0},{"Description":"CPF","Key":"3248a26c-b3c7-6a1d-219a-ae8d95c8774a","Type":5,"PositionX":281.0,"PositionY":660.0,"IsRequired":true,"PageNumber":1,"BoundsX":240.0,"BoundsY":1099.0},{"Description":"CNPJ","Key":"15a5a71a-f060-26bc-f2c5-8d5444498965","Type":6,"PositionX":281.0,"PositionY":676.0,"IsRequired":true,"PageNumber":1,"BoundsX":240.0,"BoundsY":1099.0},{"Description":"Telefone","Key":"de417c00-dea3-a324-1db8-d78a36c1e30d","Type":7,"PositionX":280.0,"PositionY":696.0,"IsRequired":true,"PageNumber":1,"BoundsX":240.0,"BoundsY":1099.0},{"Description":"Valor","Key":"d81a4e5f-b1d1-d577-c717-7de369c8633d","Type":8,"PositionX":280.0,"PositionY":711.0,"IsRequired":true,"PageNumber":1,"BoundsX":240.0,"BoundsY":1099.0},{"Description":"E-mail Signatario","Key":"57cfcef5-347c-c82d-d118-d6daab76ca5f","Type":3,"PositionX":283.0,"PositionY":623.0,"IsRequired":true,"PageNumber":1,"BoundsX":240.0,"BoundsY":1099.0},{"Description":"Informações complementares","Key":"b13a18cd-7039-a49b-264a-8eff39f2dced","Type":1,"PositionX":283.0,"PositionY":589.0,"IsRequired":true,"PageNumber":1,"BoundsX":240.0,"BoundsY":1099.0}]');

  try {
    await Api.post<IDocument>('/documents', dados);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o Documento.');
  }
};

const update = async (id: number, dados: IDocument): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.put(`/documents/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o Documento.');
  }
};

const exclude = async (id: number): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.delete(`/documents/${id}`);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao apagar o Documento.');
  }
};


// Upload
const uploads = async (dados: IUpload): Promise<IUploadResp | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.post<IUploadResp>('/Uploads', dados);

    if (data) {
      return data;
    }

    return new Error('Erro ao solicitar upload.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao solicitar upload.');
  }
};

const verifyDocument = async (dados: FormData): Promise<IDocumentValid | Error> => {
  try {
    setHeaderAuthorization();

    const { data } = await Api.post('/verify-document',
      dados,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

    if (data) {
      return data;
    }

    return new Error('Erro ao solicitar upload.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao solicitar upload.');
  }
};


const cancelDocument = async (key: string, cancelDocument: ICancelDocument): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.post(`/documents/${key}/cancel`, cancelDocument);
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao apagar o Documento.');
  }
};

const updateDescription = async (key: string, description: string): Promise<void | Error> => {
  try {
    setHeaderAuthorization();

    await Api.put(`/documents/${key}/update-description`, { description });
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao apagar o Documento.');
  }
};

const statusCount = async (participating?: boolean) => {
  setHeaderAuthorization();

  try {
    let aux = '';

    if (participating) {
      aux = `?participating=${participating}`;
    }

    const { data } = await Api.get(`/Documents/status-count${aux}`);

    if (data) {
      return data;
    }

    return new Error('Desculpe, não foi possível encontrar os Documentos.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Desculpe, não foi possível encontrar os Documentos.');
  }
};

export const DocumentsService = {
  getAll,
  getById,
  create,
  update,
  exclude,

  // documents,
  uploads,
  verifyDocument,
  cancelDocument,
  updateDescription,
  statusCount
};
