import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Col, ConfigProvider, Empty, Input, Modal, notification, Row, Space, Table, TablePaginationConfig } from 'antd';
import { CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';

import { useDebounce } from '../hooks';
import { CompanyInfoService, ICompanyInfo } from '../services/api';

interface TableProps {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  isLoading: boolean;
  handleSelection: (company: ICompanyInfo) => void;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

export const CompanyInfoModal: React.FC<TableProps> = ({ isModalOpen, setIsModalOpen, isLoading, handleSelection }) => {
  const { debounce } = useDebounce();

  const [companies, setCompanies] = useState<ICompanyInfo[]>([]);
  const [search, setSearch] = useState<string>('');
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
  ) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCompanies([]);
    }
  };

  useEffect(() => {
    debounce(() => {
      CompanyInfoService.getAll(tableParams.pagination?.current, tableParams.pagination?.pageSize, search)
        .then((result) => {
          if (result instanceof Error) {
            notification.error({
              message: result.message
            });
          } else {
            setCompanies(result.data);

            setTableParams({
              ...tableParams,
              pagination: {
                ...tableParams.pagination,
                total: result.total,
              },
            });
          }
        });
    });
  }, [JSON.stringify(tableParams), search]);

  const handleSelectionModal = (company: ICompanyInfo) => {
    handleSelection(company);
    setSearch('');
  };

  const columns: ColumnsType<ICompanyInfo> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Selecionar',
      key: 'actions',
      width: '10%',
      render: (record: ICompanyInfo) => (
        <Space size="small" style={{ justifyContent: 'center', display: 'flex' }}>
          <Button type="text" shape="circle" icon={<CheckCircleOutlined />} onClick={() => handleSelectionModal(record)} />
        </Space>
      ),
    }
  ];

  const customizeRenderEmpty = () => (
    <Empty
      imageStyle={{ height: 60 }}
      description={
        <span>
          Nenhuma empresa cadastrada.
        </span>
      }
    />
  );

  return (
    <Modal
      open={isModalOpen}
      width={800}
      onCancel={() => setIsModalOpen(false)}
      title='Selecionar empresa'
      footer={[
      ]}
    >
      <Row style={{ marginBottom: 16 }}>
        <Col span={12}>
          <Input
            placeholder='Pesquisar empresa por nome'
            onChange={(e) => setSearch(e.target.value)}
            allowClear
            suffix={
              <SearchOutlined />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={companies}
              loading={isLoading}
              size="small"
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
          </ConfigProvider>
        </Col>
      </Row>
    </Modal>
  );
};
