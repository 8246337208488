/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Form,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  notification
} from 'antd';
import {
  PlusOutlined,
  FormOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { ISigningRoles, SigningRolesService } from '../../shared/services/api';
import { usePermission } from '../../shared/contexts/PermissionContext';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

export const SigningRoles = () => {
  const { hasPermission } = usePermission();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<ISigningRoles[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limiteLinhas, setLimiteLinhas] = useState(10);

  const getData = useCallback(() => {
    setIsLoading(true);

    const limiteLinhas: number = parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS || '', 10);
    setLimiteLinhas(limiteLinhas);

    SigningRolesService.getAll(currentPage, limiteLinhas)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setDataTable(result.data);
          setTotalCount(result.total);
        }
      });
  }, [currentPage]);

  const handleRemove = async (id: number) => {
    setIsLoading(true);
    SigningRolesService
      .exclude(id)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          notification.info({
            message: 'Tipo de assinatura excluida com sucesso!'
          });
          setCurrentPage(1);
          getData();
        }
      });
  };

  const hidrateFormWithValues = async (data: ISigningRoles) => {
    navigate(`/tipos-assinatura/${data.id}`);
  };

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const createSigningRole = () => {
    navigate('/tipos-assinatura/novo-tipo');
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: ColumnsType<ISigningRoles> = [
    {
      title: 'Assinar como',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'E-mail vínculado',
      dataIndex: 'email',
      key: 'email',
    },
    {
      key: 'actions',
      render: (record: ISigningRoles) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="text" shape="circle" icon={<FormOutlined />} onClick={() => hidrateFormWithValues(record)} disabled={record.id == 1 || !hasPermission(902)} />
          <Divider type="vertical" />
          <Popconfirm
            title="Deseja realmente excluir este tipo de assinatura?"
            onConfirm={() => handleRemove(record.id)}
            okText="Sim"
            cancelText="Não"
            placement="left"
          >
            <Button type="text" shape="circle" icon={<DeleteOutlined />} disabled={record.id == 1 || !hasPermission(903)} />
          </Popconfirm>
        </Space>
      ),
    }
  ];

  const customizeRenderEmpty = () => (
    <Empty
      imageStyle={{ height: 60 }}
      description={
        <span>
          Nenhum registro encontrado.
        </span>
      }
    >
      <Button type="primary" icon={<PlusOutlined />} onClick={createSigningRole} disabled={!hasPermission(900)}>
        Novo tipo
      </Button>
    </Empty>
  );

  return (
    <>
      <Row>
        <Col span={20}>
          <Title level={3}>Tipos de assinatura</Title >
        </Col>
        <Col span={4} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button type="primary" onClick={createSigningRole} icon={<PlusOutlined />} loading={isLoading} disabled={!hasPermission(900)}>
            Novo tipo
          </Button>
        </Col>
      </Row>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          columns={columns}
          dataSource={dataTable}
          rowKey={(record) => record.id}
          loading={isLoading}
          size="small"
          pagination={false}
        />
        <Pagination
          pageSize={limiteLinhas}
          style={{ float: 'right', margin: '5px' }}
          current={currentPage}
          onChange={onChangePage}
          total={totalCount}
          showTotal={total => `Total ${total} registro(s)`}
        />
      </ConfigProvider>
    </>
  );
};
