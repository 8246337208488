/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Pagination, Row, Skeleton, Space, Spin, Statistic, Table, Tag, Tooltip, Typography, message, notification } from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Meta from 'antd/es/card/Meta';
import { Content } from 'antd/es/layout/layout';
import {
  CheckCircleOutlined,
  FieldTimeOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { DocumentsService, IDocument } from '../../shared/services/api';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { usePermission } from '../../shared/contexts/PermissionContext';

const { Title } = Typography;

interface ITotalByStatus {
  documentStatus: number;
  total: number;
  description: string;
  color: string;
  iconColor: string;
  icon: JSX.Element
}

export const Dashboard = () => {
  const navigate = useNavigate();
  const { user, hasPermission } = usePermission();

  const [isLoading, setIsLoading] = useState(true);
  const [dataTable, setDataTable] = useState<IDocument[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limiteLinhas, setLimiteLinhas] = useState(10);
  // const [user, setUser] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalByStatus, setTotalByStatus] = useState<ITotalByStatus[]>([
    {
      documentStatus: 1,
      total: 0,
      description: 'Finalizado(s)',
      color: '#b7eb8f',
      iconColor: '#389e0d',
      icon: <CheckCircleOutlined />
    },
    {
      documentStatus: 0,
      total: 0,
      description: 'Pendente(s)',
      color: '#ffd591',
      iconColor: '#d46b08',
      icon: <ClockCircleOutlined />
    },
    {
      documentStatus: 3,
      total: 0,
      description: 'Cancelado(s)',
      color: '#ffa39e',
      iconColor: '#cf1322',
      icon: <CloseCircleOutlined />
    },
    {
      documentStatus: 4,
      total: 0,
      description: 'Expirado(s)',
      color: '#e9e9e9',
      iconColor: '#000',
      icon: <FieldTimeOutlined />
    }
  ]);

  useEffect(() => {
    async function getDocuments() {
      setIsLoading(true);
      const limiteLinhas: number = parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS || '', 10);
      setLimiteLinhas(limiteLinhas);

      await DocumentsService.getAll(currentPage, limiteLinhas, undefined, '0', '', true)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            console.log(result.message);
            // notification.warning({
            //   message: result.message
            // });
          } else {
            setDataTable(result.data);
            setTotalCount(result.total);

            // const updatedTotalByStatus = totalByStatus.map(item => {
            //   const foundItem = result.totalByStatus.find(apiItem => apiItem.documentStatus === item.documentStatus);
            //   if (foundItem) {
            //     return {
            //       ...item,
            //       total: foundItem.total,
            //     };
            //   }
            //   return item; // Caso não encontre correspondência, mantenha o item original
            // });

            // Atualize o estado com os valores atualizados
            // setTotalByStatus(updatedTotalByStatus);


            // setTotalByStatus(novosObjetos);
            // setUser(getUser());
          }
        });
    }

    async function statusCount() {
      setIsLoading(true);

      await DocumentsService.statusCount(true)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            console.log(result.message);
            // notification.warning({
            //   message: result.message
            // });
          } else {
            const updatedTotalByStatus = totalByStatus.map(item => {
              const foundItem = result.find((apiItem: { documentStatus: number; }) => apiItem.documentStatus === item.documentStatus);
              if (foundItem) {
                return {
                  ...item,
                  total: foundItem.total,
                };
              }
              return item; // Caso não encontre correspondência, mantenha o item original
            });

            // Atualize o estado com os valores atualizados
            setTotalByStatus(updatedTotalByStatus);
          }
        });
    }

    getDocuments();
    statusCount();
  }, [currentPage]);

  const viewDocument = async (key: string) => {
    navigate(`/documentos/detalhe/${key}`);
  };

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const columns: ColumnsType<IDocument> = [
    {
      title: 'Documento',
      dataIndex: 'description',
      key: 'description',
      render: (_, { description, fileName, participants }) => {
        const total = participants.filter(x => x.isSigner).length;
        const remaining = participants.filter(x => x.isSigner && x.hasSigned).length;

        return (
          <>
            <Title level={4}>{description}</Title>
            <small>{fileName}</small><br />
            <small>{remaining}/{total} Assinaturas</small>
          </>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      render: (_, { status }) => {
        if (status === 0) {
          return <Tag color="orange">Pendente</Tag>;
        } else if (status === 1) {
          return <Tag color="green">Finalizado</Tag>;
        } else if (status === 2) {
          return <Tag color="yellow">Anulado</Tag>;
        } else if (status === 3) {
          return <Tag color="red">Cancelado</Tag>;
        } else if (status === 4) {
          return <Tag color="default">Expirado</Tag>;
        }
      }
    },
    {
      title: 'Data Criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['lg'],
      render: (_, { createdAt }) => {
        return moment(createdAt).format('DD/MM/YYYY HH:mm');
      }
    },
    {
      key: 'actions',
      render: (_, { key }) => (
        <Space size="small" style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Tooltip title="Ver documento">
            <Button type="text" shape="circle" icon={<EyeOutlined />} onClick={() => viewDocument(key)} />
          </Tooltip>
        </Space>
      ),
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <Title level={2}>Olá, {user?.name}</Title>
      <Content>
        <Row gutter={15}>
          {totalByStatus.map((status) => (
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6} key={status.documentStatus} style={{ marginBottom: 8 }}>
              <Card
                style={{ width: '100%', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.10), 0 1px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.05)' }}
                bordered={false}
                styles={{ body: { borderTopLeftRadius: 10, borderTopRightRadius: 10 } }}
              >
                <Meta
                  avatar={<Avatar icon={status.icon} size={80} style={{ backgroundColor: 'transparent', color: status.iconColor }} />}
                  title={status.description}
                  description={status.total.toString()}
                />
              </Card>
            </Col>
          ))}
        </Row>
        <Row
          style={{ marginTop: '30px' }}
        >
          <Col span={24}>
            <Title level={4}>{totalCount} documento(s) pendente(s) de assinatura.</Title>
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={dataTable}
              rowKey={(record) => record.key}
              loading={isLoading}
              pagination={false}
            />
            <Pagination
              pageSize={limiteLinhas}
              style={{ float: 'right', margin: '5px' }}
              current={currentPage}
              onChange={onChangePage}
              total={totalCount}
              showTotal={total => `Total ${total} registro(s)`}
            />
          </Col>
        </Row>
      </Content>
    </Spin>
  );
};
