/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {
  AppstoreOutlined,
  UserOutlined,
  TagsOutlined,
  SwapOutlined,
  FolderOutlined,
  SolutionOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  SignatureOutlined,
  BankOutlined
} from '@ant-design/icons';

import { usePermission } from '../contexts/PermissionContext';

export const Nav: React.FC = () => {
  const location = useLocation();
  const selectedKey = location.pathname.substring(1);

  const { hasPermission } = usePermission();

  const menuList = [
    {
      key: 'dashboard',
      name: 'Dashboard',
      icon: <AppstoreOutlined />,
      url: '/pagina-inicial',
      hidden: false
    },
    {
      key: 'documentos',
      name: 'Documentos',
      icon: <FolderOutlined />,
      url: '/documentos',
      hidden: !hasPermission(100)
    },
    {
      key: 'modelos',
      name: 'Modelos',
      icon: <FileTextOutlined />,
      url: '/modelos',
      hidden: !hasPermission(601)
    },
    {
      key: 'grupos',
      name: 'Fluxos',
      icon: <SwapOutlined />,
      url: '/fluxos',
      hidden: !hasPermission(501)
    },
    {
      key: 'signingRole',
      name: 'Tipos de Assinatura',
      icon: <SignatureOutlined />,
      url: '/tipos-assinatura',
      hidden: !hasPermission(301)
    },
    {
      key: 'empresas',
      name: 'Empresas',
      icon: <BankOutlined />,
      url: '/empresas',
      hidden: !hasPermission(801)
    },
    {
      key: 'contatos',
      name: 'Contatos',
      icon: <SolutionOutlined />,
      url: '/contatos',
      hidden: !hasPermission(401)
    },
    {
      key: 'usuarios',
      name: 'Usuários',
      icon: <UserOutlined />,
      url: '/usuarios',
      hidden: !hasPermission(200)
    },
    {
      key: 'perfis',
      name: 'Perfis de usuários',
      icon: <TagsOutlined />,
      url: '/perfis-usuarios',
      hidden: !hasPermission(301)
    },
    {
      key: 'relatorio',
      name: 'Relatorio',
      icon: <FileTextOutlined />,
      url: '/relatorio',
      hidden: !hasPermission(700)
    },
    {
      key: 'validarAssinatura',
      name: 'Validar assinatura',
      icon: <CheckCircleOutlined />,
      url: '/documentos/validar-assinatura-documentos',
      hidden: false
    },
  ];
  // );

  // useEffect(() => {
  //   const updatedMenuList = menuList.map(item => {
  //     if (permissions.includes(parseInt(item.key))) {
  //       return { ...item, hidden: false };
  //     }
  //     return item;
  //   });

  //   setMenuList(updatedMenuList);
  // }, [permissions]);

  return (
    <Menu theme="dark" mode="inline" selectable={!!selectedKey} defaultSelectedKeys={[menuList[0].key]} >
      {menuList.map(menu => (
        <Menu.Item icon={menu.icon} key={menu.key} hidden={menu.hidden}>
          <NavLink to={menu.url}>{menu.name}</NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );
};
