import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
  notification
} from 'antd';
import {
  ArrowLeftOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { ISigningRoles, ISigningRolesUpdate, SigningRolesService } from '../../shared/services/api';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermission } from '../../shared/contexts/PermissionContext';
import { IUser } from '../../shared/services/api/UsersService';
import { AssociatedUsersTable } from '../../shared/components';

const { Title } = Typography;
const { TabPane } = Tabs;

export const SigningRoleEdit = () => {
  const navigate = useNavigate();
  const { user } = usePermission();
  const { id = 'novo-tipo' } = useParams<'id'>();
  const [signingRole, setSigningRole] = useState<ISigningRoles>({} as ISigningRoles);

  const [form] = Form.useForm();
  const [title, setTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const [dataTable, setDataTable] = useState<IUser[]>([]);

  //#region ######### Initialize
  useEffect(() => {
    async function getSigningRole(id: string) {
      if (id !== 'novo-tipo') {
        setIsLoading(true);
        await SigningRolesService.getById(Number(id))
          .then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              notification.error({
                message: result.message
              });
              goBack();
            } else {
              setSigningRole(result);
              form.setFieldsValue(result);
              setTitle(result.description);

              if (result.associatedUsers) {
                setDataTable(result.associatedUsers);
              }
            }
          });
      } else {
        setTitle('Novo tipo');
      }
    }

    getSigningRole(id);
  }, [id]);

  const goBack = () => {
    navigate('/tipos-assinatura');
  };

  const handleSave = async () => {
    form.validateFields()
      .then((values) => {
        if (id !== 'novo-tipo') {
          updateSigningRole(values);
        } else {
          createSigningRole(values);
        }
      })
      .catch((error) => {
        Modal.warning({
          title: 'Atenção',
          content: error.errorFields[0].errors[0]
        });
        return;
      });
  };

  const createSigningRole = async (data: ISigningRoles) => {
    setIsLoading(true);
    data.associatedUsers = [];
    await SigningRolesService.create(data)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
          goBack();
        } else {
          notification.success({
            message: 'Tipo de assinatura criada com sucesso!'
          });
          goBack();
        }
      });
  };

  const updateSigningRole = async (data: ISigningRolesUpdate) => {
    setIsLoading(true);
    const usersIds = dataTable.map(user => user.id);
    data.associatedUsers = usersIds ? usersIds : [];
    await SigningRolesService.update(Number(id), data)
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
          goBack();
        } else {
          notification.success({
            message: 'Tipo de assinatura atualizada com sucesso!'
          });
          goBack();
        }
      });
  };

  const handleRemoveUser = (id: number) => {
    setDataTable(dataTable.filter((user) => user.id !== id));
  };

  const handleAddUser = (user: IUser) => {
    if (dataTable.some((aux) => aux.id === user.id)) {
      Modal.warning({
        title: 'Atenção',
        content: 'O usuário ja foi informado.'
      });
      return false;
    } else {
      setDataTable([...dataTable, user]);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Row style={{ paddingBottom: '16px' }}>
        <Col span={4}>
          <Button type="text" onClick={goBack} icon={<ArrowLeftOutlined />}>
            Voltar para tipos
          </Button>
        </Col>
        <Col span={20} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Space>
            <Button
              type="primary"
              onClick={handleSave}
              icon={<CheckOutlined />}
              loading={isLoading}
            >
              Salvar tipo
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={3}>{title}</Title>
        </Col>
      </Row>
      <Tabs defaultActiveKey={'1'}>
        <TabPane tab="Dados" key="1">
          <Row>
            <Col span={24}>
              <Form
                layout="vertical"
                form={form}
                hideRequiredMark
                onFinish={() => { }}
                initialValues={signingRole}
              >
                <Row>
                  <Form.Item name="id" style={{ display: 'none' }}>
                    <Input readOnly name="id" />
                  </Form.Item>
                  <Col span={10}>
                    <Form.Item
                      name="description"
                      label="Assinar como"
                      rules={[{ required: true, message: 'Assinar como é um campo obrigatório' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[{ type: 'email', message: 'E-mail informado é inválido' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

              </Form>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Acesso" key="2" disabled={user?.id !== signingRole?.userId}>
          <AssociatedUsersTable
            dataTable={dataTable}
            isLoading={isLoading}
            handleRemoveUser={handleRemoveUser}
            handleAddUser={handleAddUser}
          />
        </TabPane>
      </Tabs>
    </Spin>
  );
};
