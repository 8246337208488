/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Avatar, Button, Col, Dropdown, Input, Layout, MenuProps, Modal, Row, Space, Typography, message, notification, theme } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  DatabaseOutlined,
  ThunderboltOutlined,
  CopyOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Nav } from './Nav';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../contexts';
import { useAuth } from 'react-oidc-context';
import { usePermission } from '../contexts/PermissionContext';
import copy from 'clipboard-copy';
import { AuthService, ITokenResult } from '../services/api';

const { Title } = Typography;

interface Props {
  children: React.ReactNode;
}

interface IAccessToken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
  token_type: string;
}

export const Container: React.FC<Props> = ({ children }) => {
  const { Header, Sider, Content } = Layout;
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user, hasPermission } = usePermission();

  const auth = useAuth();

  const [initials, setInitials] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState<ITokenResult>({} as ITokenResult);

  useEffect(() => {
    if (user) {
      const init = user.name
        .split(' ')
        .slice(0, 2)
        .map((nome) => nome[0])
        .join('');
      setInitials(init);
    }
  }, [user]);

  const logout = async () => {
    await auth.signoutSilent()
      .finally(() => {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('profile');
        navigate('/login', { replace: true });
      });
  };

  const handlerLogout = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Atenção',
      content: 'Deseja realmente finalizar sua sessão?',
      onOk() { logout(); },
      onCancel() { Modal.destroyAll(); },
      cancelText: 'Cancelar',
      okText: 'Sim, finalizar',
    });
  };

  const items: MenuProps['items'] = [
    {
      type: 'group',
      label: user?.name,
    },
    {
      type: 'group',
      label: <small>{user?.email}</small>
    },
    {
      type: 'divider'
    },
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://engmaxsign.tomticket.com/kb">
          Central de Ajuda
        </a>
      ),
      key: 'helpcenter',
      icon: <DatabaseOutlined />,
    },
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://engmaxsign.tomticket.com">
          Help Desk
        </a>
      ),
      key: 'helpdesk',
      icon: <QuestionCircleOutlined />,
    },
    // {
    //   label: 'Documentos para assinar',
    //   key: 'mySignatures',
    //   icon: <EditOutlined />,
    // },
    // {
    //   label: 'Meu perfil',
    //   key: 'myprofile',
    //   icon: <LogoutOutlined />
    // },
    {
      label: 'Sair',
      key: 'logout',
      icon: <LogoutOutlined />
    },
  ];

  if (hasPermission(701)) {
    items.splice(3, 0, {
      label: 'Obter Token API',
      key: 'obter-token',
      icon: <ThunderboltOutlined />
    });
  }

  // if (hasPermission(801)) {
  //   items.splice(3, 0, {
  //     label: 'Configurações',
  //     key: 'settings',
  //     icon: <SettingOutlined />,
  //   });
  // }

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handlerOptions: MenuProps['onClick'] = (e) => {
    if (e.key === 'mySignatures') {
      navigate('/documentos/meus-documentos');
    } else if (e.key === 'logout') {
      handlerLogout();
    } else if (e.key === 'settings') {
      navigate('/configuracoes');
    } else if (e.key === 'obter-token') {
      setIsModalOpen(true);
    } else if (e.key === 'myprofile') {
      navigate('/meu-perfil');
    }
  };

  const gerarToken = async () => {
    // const data = {
    //   client_id: 'restapi',
    //   grant_type: 'password',
    //   client_secret: 'AsjgWtaYB0JdUkOSTrXlN9386MJMnbdN',
    //   username: 'acessoapi',
    //   password: 'engmax@2024'
    // };

    // const url = 'https://sso.engmaxsign.com.br/realms/engmaxsign-dev/protocol/openid-connect/token';

    // try {
    //   const response = await axios.post(url, data, {
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //   });
    //   setResponse(response.data);
    // } catch (error) {
    //   console.error('Error fetching data:', error);
    // }

    AuthService.tokenGeneration()
      .then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          notification.error({
            message: result.message
          });
        } else {
          setResponse(result);
        }
      });
  };

  const handleCopy = (token: string | undefined) => {
    if (token) {
      copy(token);
      message.info('Token copiado para a área de transferência');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        theme="dark"
        trigger={null}
        collapsible
        collapsed={menuCollapsed}
        breakpoint='md'
        onBreakpoint={(broken) => {
          setMenuCollapsed(broken);
        }}
      // onCollapse={(collapsed, type) => {
      //   console.log('collapsed', collapsed);
      //   console.log('type', type);
      // }}
      >
        <div style={{ height: '65px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <img src="assets/logo.svg" alt="Logo" style={{ height: '45px' }} />
          {!menuCollapsed && <Title level={4} style={{ color: colorBgContainer }}>Engmax Sign</Title>}
        </div>
        <Nav />

      </Sider>
      <Layout className="site-layout">
        <Header
          style={{ padding: 0, background: colorBgContainer }}
        >
          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
            <Button
              type="text"
              icon={menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setMenuCollapsed(!menuCollapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />

            <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
              <Dropdown menu={{ items, onClick: handlerOptions }}>
                <Space direction="vertical" size={16} style={{ paddingRight: 20, paddingLeft: 16 }}>
                  <Space wrap size={16}>
                    {/* <Avatar size="large" icon={<UserOutlined />} /> */}
                    <Avatar style={{ verticalAlign: 'middle' }} size="large">
                      {initials}
                    </Avatar>
                  </Space>
                </Space>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
      <Modal
        open={isModalOpen}
        closeIcon={true}
        onCancel={() => setIsModalOpen(false)}
        title='Obter token para acesso a API'
        footer={[
        ]}
      >
        <Row style={{ marginBottom: 50 }}>
          <Col span={24}>
            <Button type="primary" onClick={gerarToken} icon={<SettingOutlined />} loading={isLoading}>Gerar Token</Button>
          </Col>
          <Col span={24}>
            <Space.Compact style={{ width: '100%', marginTop: 16 }}>
              <Input type='text' value={response.token} />
              <Button type="default" onClick={() => handleCopy(response.token)} icon={<CopyOutlined />}>Copiar</Button>
            </Space.Compact>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button onClick={() => setIsModalOpen(false)} style={{ marginRight: 8 }} htmlType="button">
              Cancelar
            </Button>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};
