export const validateCNPJ = (rule: any, value: any, callback: any) => {
  // Remover caracteres não numéricos
  const cleanCNPJ = value.replace(/\D/g, '');

  // Verificar se possui 14 dígitos
  if (cleanCNPJ.length !== 14) {
    callback('CNPJ deve ter 14 dígitos');
    return;
  }

  // Verificar se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cleanCNPJ)) {
    callback('CNPJ inválido');
    return;
  }

  // Algoritmo de verificação do CNPJ
  const validateDigit = (cnpj: string, position: number) => {
    const weights = position === 12 ? [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2] : [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let sum = 0;

    for (let i = 0; i < weights.length; i++) {
      sum += parseInt(cnpj.charAt(i)) * weights[i];
    }

    const remainder = sum % 11;
    const digit = remainder < 2 ? 0 : 11 - remainder;

    return digit === parseInt(cnpj.charAt(position));
  };

  // Verificar o primeiro dígito verificador (12º)
  if (!validateDigit(cleanCNPJ, 12)) {
    callback('CNPJ inválido');
    return;
  }

  // Verificar o segundo dígito verificador (13º)
  if (!validateDigit(cleanCNPJ, 13)) {
    callback('CNPJ inválido');
    return;
  }

  callback();
};
