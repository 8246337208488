export const isCPFValid = (cpf: string): boolean => {
  // Remover caracteres não numéricos
  const cleanCPF = cpf.replace(/\D/g, '');

  // Verificar se possui 11 dígitos
  if (cleanCPF.length !== 11) {
    return false;
  }

  // Verificar se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cleanCPF)) {
    return false;
  }

  // Algoritmo de verificação do CPF
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
  }
  const remainder = (sum * 10) % 11;

  return (remainder === 10 || remainder === 11) ?
    parseInt(cleanCPF.charAt(9)) === 0 :
    parseInt(cleanCPF.charAt(9)) === remainder;
};

export const validateCPF = (rule: any, value: any, callback: any) => {
  // Remover caracteres não numéricos
  const cleanCPF = value.replace(/\D/g, '');

  // Verificar se possui 11 dígitos
  if (cleanCPF.length !== 11) {
    callback('CPF deve ter 11 dígitos');
    return;
  }

  // Verificar se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cleanCPF)) {
    callback('CPF inválido');
    return;
  }

  // Algoritmo de verificação do CPF
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cleanCPF.charAt(i)) * (10 - i);
  }
  const remainder = (sum * 10) % 11;

  if ((remainder === 10 || remainder === 11) ?
    parseInt(cleanCPF.charAt(9)) !== 0 :
    parseInt(cleanCPF.charAt(9)) !== remainder) {
    callback('CPF inválido');
    return;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cleanCPF.charAt(i)) * (11 - i);
  }
  const secondRemainder = (sum * 10) % 11;

  if ((secondRemainder === 10 || secondRemainder === 11) ?
    parseInt(cleanCPF.charAt(10)) !== 0 :
    parseInt(cleanCPF.charAt(10)) !== secondRemainder) {
    callback('CPF inválido');
    return;
  }

  callback();
};
